class ZIDXAccountLeadAssign implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountLeadAssignContainer";
    }
    getMatchURL(){
        return "/account/leads/assign";
    }
    render(){
        // console.log("lead assign");
    }
}